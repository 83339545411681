<template>
  <div>
    <div v-if="!isLoading" class="box box-default">
      <div class="box-header">
        <div class="flex flex-col-sm gap-8 align-items-center justify-between">
          <h3 class="box-title">Platform Scheduling</h3>
          <div class="flex flex-col-sm gap-8 align-items-center">
            <span class="text-bold text-danger">{{ searching }}</span>
            <button class="btn btn-primary btn-sm btn-flat" @click="executeAllEnabled()">Run all Enabled
            </button>
            <button class="btn btn-primary btn-sm btn-flat" @click="addPlatformScheduling()">Add Platform Scheduling
            </button>
            <input v-model="keyword" aria-label="search" class="form-control" placeholder="Search..." type="text"
              @keyup="search()" />
          </div>
        </div>
      </div>
      <div class="box-body no-padding">
        <div v-if="meta.total" class="footer">
          <pagination :meta="meta" @pageChange="updatePage"></pagination>
        </div>
        <div class="table-responsive">
          <table class="table table-striped table-condensed">
            <thead>
              <tr>
                <th>ID</th>
                <th>URL</th>
                <th>Description</th>
                <th>Enabled</th>
                <th>From</th>
                <th>To</th>
                <th>From2</th>
                <th>To2</th>
              </tr>
            </thead>
            <tbody>
              <template v-if="records.length > 0">
                <tr v-for="(record, index) in records" :key="index">
                  <td>{{ record.ID }}</td>
                  <td style="width: 300px; word-break: break-all;">
                    <span class="flex align-items-center justify-between">
                      <span
                        v-tooltip="{ content: record.URL, trigger: 'click focus hover', isOpen: true, classes: 'wide-tooltip' }"
                        class="pointer" @click="launchUpdatePlatformSchedulingModal(record, 'URL', 'URL', 'text')">
                        {{ excerpt(record.URL) }}
                      </span>
                      <button class="btn btn-primary btn-flat btn-sm ml-8" @click="openPage(record.URL)">Execute</button>
                    </span>
                  </td>
                  <td class="no-wrap">
                    <span class="pointer"
                      @click="launchUpdatePlatformSchedulingModal(record, 'Description', 'Description', 'textarea')">
                      {{ record.Description }}
                    </span>
                  </td>
                  <td>
                    <span class="pointer" @click="togglePlatformSchedulingBoolFields(record, 'Enabled')"
                      v-html="formatBoolean(record.Enabled)"></span>
                  </td>
                  <td>
                    <span class="pointer" @click="launchUpdatePlatformSchedulingModal(record, 'From', 'From', 'time')">
                      {{ record.From }}
                    </span>
                  </td>
                  <td>
                    <span class="pointer" @click="launchUpdatePlatformSchedulingModal(record, 'To', 'To', 'time')">
                      {{ record.To }}
                    </span>
                  </td>
                  <td>
                    <span class="pointer" @click="launchUpdatePlatformSchedulingModal(record, 'From2', 'From2', 'time')">
                      {{ record.From2 }}
                    </span>
                  </td>
                  <td class="action-sm pr-16">
                    <span class="pointer" @click="launchUpdatePlatformSchedulingModal(record, 'To2', 'To2', 'time')">
                      {{ record.To2 }}
                    </span>
                  </td>
                </tr>
              </template>
              <template v-else>
                <tr>
                  <td colspan="8">No record found!</td>
                </tr>
              </template>
            </tbody>
          </table>
        </div>
        <div v-if="meta.total" class="footer">
          <pagination :meta="meta" @pageChange="updatePage"></pagination>
        </div>
      </div>
    </div>
    <!-- Update Platform Scheduling Modal -->
    <update-platform-scheduling-modal></update-platform-scheduling-modal>
  </div>
</template>

<script>
import Pagination from "@/components/Pagination";
import { mapGetters } from "vuex";
import { START_LOADING, STOP_LOADING } from "@/store/keys";
import UpdatePlatformSchedulingModal from "@/components/modals/UpdatePlatformSchedulingModal";
import { logout, saveNotification } from "../lib/utils";

export default {
  name: "PlatformScheduling",
  components: {
    UpdatePlatformSchedulingModal,
    Pagination,
  },
  data() {
    return {
      records: [],
      meta: {
        total: 0,
        page: 1,
        offset: 0,
        limit: Number.parseInt(process.env.VUE_APP_LIMIT, 10),
      },
      keyword: "",
      searching: "",
    };
  },
  computed: {
    ...mapGetters([
      "isLoading",
      "jwtToken",
      "currentUser",
    ]),
  },
  methods: {
    updatePage(page) {
      this.getPaginatedRecords(page);
    },
    excerpt(str, length = 30) {
      if (str) {
        return str.length > length ? str.substr(0, length) + "..." : str;
      }
      return str;
    },
    search() {
      this.searching = "Searching...";
      if (this.timer) {
        clearTimeout(this.timer);
      }
      this.timer = setTimeout(() => {
        this.getPaginatedRecords(1);
      }, 500);
    },
    getPaginatedRecords(page = 1) {
      this.$store.commit(START_LOADING);
      const apiBaseUrl = process.env.VUE_APP_ADMIN_API_URL;
      this.$http.get(`${apiBaseUrl}/?Call=ListMisc&type=platform_scheduling`, {
        headers: {
          Authorization: "Bearer " + this.jwtToken,
        },
        params: {
          page,
          keyword: this.keyword ? this.keyword.trim() : "",
          limit: Number.parseInt(this.meta.limit),
        },
      }).then((response) => {
        this.$store.commit(STOP_LOADING);
        this.searching = "";
        if (response.status === 200) {
          this.records = response.data.data.records;
          this.meta = response.data.data.meta;
        }
      }).catch(error => {
        this.$store.commit(STOP_LOADING);
        this.searching = "";
        this.$toast.fire("", error.data.message, "error");
        saveNotification(error.data.message);
      });
    },
    getRecordById(id, index) {
      const apiBaseUrl = process.env.VUE_APP_ADMIN_API_URL;
      this.$http.get(`${apiBaseUrl}/?Call=ListMisc&type=platform_scheduling`, {
        headers: {
          Authorization: "Bearer " + this.jwtToken,
        },
        params: {
          id,
        },
      }).then((response) => {
        this.searching = "";
        if (response.status === 200) {
          const record = response.data.data;
          if (index !== -1) {
            this.records.splice(index, 1, record);
          }
        }
      }).catch(error => {
        this.searching = "";
        this.$toast.fire("", error.data.message, "error");
        saveNotification(error.data.message);
      });
    },
    formatBoolean(val) {
      const label = val ? "Yes" : "No";
      const lblClass = val ? "label-success" : "label-danger";
      return `<label class="label ${lblClass}">${label}</label>`;
    },
    addPlatformScheduling() {
      this.$swal.fire({
        title: "",
        text: "Please wait...",
        showConfirmButton: false,
        backdrop: true,
      });
      const apiBaseUrl = process.env.VUE_APP_API_URL;
      this.$http.get(`${apiBaseUrl}/v2REAPI?Call=PlatformSchedulingInsert`, {
        params: {
          MerchantID: this.currentUser.ID,
          APIKey: this.currentUser.APIKey,
          output: "json",
        },
      }).then(response => {
        if (response.data.status_code === 200) {
          if (response.data.result.toLowerCase() === "success") {
            this.$swal.close();
            this.searching = "Please wait ...";
            this.getPaginatedRecords(1);
          }
        } else if (response.data.status_code === 400) {
          if (response.data.message.toLowerCase() === "no permission") {
            logout();
          }
        }
      }).catch(error => {
        this.$swal.close();
        console.log(error);
      });
    },
    getOptions(key) {
      let options = [];
      switch (key) {
        case "Enabled":
          options = [
            {
              id: 0,
              label: "No",
            },
            {
              id: 1,
              label: "Yes",
            },
          ];
          break;
      }
      return options;
    },
    togglePlatformSchedulingBoolFields(record, key) {
      const index = this.records.indexOf(record);
      const payload = {
        MerchantID: this.currentUser.ID,
        APIKey: this.currentUser.APIKey,
        ID: record.ID,
        Key: key,
        Value: Number(record[key]) === 0 ? 1 : 0,
        output: "json",
      };
      const url = process.env.VUE_APP_API_URL;
      this.$http.post(`${url}/v2REAPI?Call=PlatformSchedulingUpdate`, this.qs.stringify(payload), {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        withCredentials: true,
      }).then(response => {
        if (response.data && response.data.status_code === 400) {
          if (response.data.message.toLowerCase() === "no permission") {
            logout();
          }
        } else {
          this.$toast.fire("", response.data.message, "success");
          saveNotification(response.data.message);
          this.getRecordById(record.ID, index);
        }
      }).catch(error => {
        console.log(error);
      });
    },
    launchUpdatePlatformSchedulingModal(record, key, label, type) {
      const index = this.records.indexOf(record);
      const options = this.getOptions(key);
      this.$bus.$emit("update-platform-scheduling-modal", {
        title: "Update " + label,
        valueLabel: label,
        record: {
          ID: record.ID,
          Key: key,
          Value: record[key],
        },
        type,
        options,
        confirm: () => {
          this.getRecordById(record.ID, index);
        },
      });
    },
    openPage(url) {
      this.$swal.fire({
        title: "Platform Scheduling",
        text: "Are you sure?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#00a65a",
        confirmButtonText: "Yes",
        cancelButtonText: "No",
        focusCancel: true,
      }).then(({ value }) => {
        if (value && value === true) {
          window.open(url, "_blank");
        }
      });
    },
    executeAllEnabled() {
      this.$swal.fire({
        title: "Platform Scheduling",
        text: "Are you sure, want to execute all enabled?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#00a65a",
        confirmButtonText: "Yes",
        cancelButtonText: "No",
        focusCancel: true,
      }).then(({ value }) => {
        if (value && value === true) {
          this.records.forEach(record => {
            if (record.Enabled) {
              window.open(record.URL, "_blank");
            }
          });
        }
      });
    },
  },
  mounted() {
    this.getPaginatedRecords();
  },
  beforeDestroy() {
    this.$store.commit(STOP_LOADING);
  },
};
</script>

<style scoped></style>
